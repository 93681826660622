import * as React from "react";
import { styled, MUIStyledCommonProps, Theme } from "@mui/system";
import { TextField as OriginalTextField } from "@mui/material"

/** テキストフィールド プロパティ */
export interface ITextFieldProps extends MUIStyledCommonProps {
    /** ラベル */
    label: string;
    /** 値 */
    value: string;
    /** 変更イベント */
    onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: string) => void;
    /** 複数行入力可能か否か */
    multiline?: boolean;
    /** バリデーション */
    validation?: (newValue: string) => { message?: string, result: boolean };
    /** バリデーションエラーイベント */
    onError?: () => void;
    /** バリデーション成功イベント */
    onSuccess?: () => void;
    /** 必須か否か */
    required?: boolean;
    /** 入力エラーか否か */
    error?: boolean;
    /** 空入力の際に表示する代替文字列 */
    placeholder?: string;
    /** 複数行入力の際の行数（初期表示） */
    rows?: number;
}

const MyOriginalTextField = styled(OriginalTextField)({
    backgroundColor: "white"
})

/** テキストフィールド */
export default function TextField({ label, value, onChange, multiline, validation, onError, onSuccess, required, error, placeholder, rows, ...props }: ITextFieldProps) {

    const [errorMessage, setErrorMessage] = React.useState<string>("");

    // 変更イベント
    const changeEvent = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newValue = e.target.value;
        let hasErr = false;
        let err: string | undefined = undefined;

        // バリデーション
        if(required === true && !newValue) {
            hasErr = true;
            err = "入力してください。";
        } else if(validation) {
            const result = validation(newValue);
            if(result.result !== true) {
                hasErr = true;
                err = result.message;
            }
        }
        if(hasErr) {
            if(onError) onError();
            if(err) setErrorMessage(err); 
        } else {
            if(onSuccess) onSuccess();
            setErrorMessage("");
        }

        onChange(e, newValue);
    }

    // 描画
    return (
        <div {...props}>
            <MyOriginalTextField
                variant="outlined"
                multiline={multiline}
                placeholder={placeholder}
                rows={rows}
                label={label}
                value={value}
                onChange={changeEvent}
                required={required}
                error={error}
                helperText={errorMessage}
                fullWidth
                InputLabelProps={{ shrink: true, style: { fontSize: "2.0rem", fontWeight: 600 } }}
                inputProps={{ style: { fontSize: "1.8rem", fontWeight: 500 } }}
                FormHelperTextProps={{ style: { fontSize: "1.2rem" } }}
            />
        </div>
    )
}