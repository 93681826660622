import * as React from "react";
import { styled } from "@mui/system";
import SectionTitle from "../atoms/common/sectionTitle";
import GoToPageButton from "../atoms/common/goToPageButton";
import TextField from "../atoms/common/textField";
import ColorButton from "../atoms/common/colorButton";
import { navigate } from "gatsby";

const MyContactArea = styled("div")({
    height: "calc(100vh - 10.0rem - 2.0rem)",
    position: "relative"
})
const MyContentsArea = styled("div")({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "calc(100% - 6.0rem)"
})
const MyButtonArea = styled("div")({
    height: "6.0rem"
})
const MyContents = styled("div")({
    display: "flex",
    flexDirection: "column",
    rowGap: "3.0rem"
})
const MyFormArea = styled("div")({
    display: "flex",
    flexDirection: "column",
    rowGap: "1.0rem"
})
const MyTextField = styled(TextField)({
    width: "70.0rem",
    height: "10.0rem"
})
const MyTextArea = styled(TextField)({
    width: "70.0rem",
    height: "20.0rem"
})
const MySendArea = styled("div")({
    display: "flex",
    justifyContent: "end"
})

/** Contact領域 */
export default function ContactDetailArea() {

    // お名前
    const [name, setName] = React.useState<string>("");
    const [nameError, setNameError] = React.useState<boolean>(false);

    // メールアドレス
    const [mail, setMail] = React.useState<string>("");
    const [mailError, setMailError] = React.useState<boolean>(false);

    // メッセージ
    const [message, setMessage] = React.useState<string>("");
    const [messageError, setMessageError] = React.useState<boolean>(false);

    // メールアドレスチェック
    const emailValidation = (v: string) => {
        const regexp = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]+.[A-Za-z0-9]+$/;
        if(!regexp.test(v)) {
            return { message: "メールアドレスの形式が正しくありません。", result: false };
        } else {
            return { message: "", result: true };
        }
    }

    // 送信
    const canSend = (() => {
        return name?.length > 0 && mail?.length > 0 && message?.length > 0 && nameError === false && mailError === false && messageError === false;
    })()
    const onSend = () => {
        navigate("/");
    }

    return (
        <MyContactArea>
            <MyContentsArea>
                <MyContents>
                    <SectionTitle text1="contact" />
                    <MyFormArea>
                        <MyTextField
                            label="お名前"
                            value={name}
                            required
                            error={nameError}
                            onChange={(e, v) => { setName(v); }}
                            onError={() => { setNameError(true); }}
                            onSuccess={() => { setNameError(false); }}
                        />
                        <MyTextField
                            label="メールアドレス"
                            value={mail}
                            required
                            error={mailError}
                            onChange={(e, v) => { setMail(v); }}
                            onError={() => { setMailError(true); }}
                            onSuccess={() => { setMailError(false); }}
                            validation={emailValidation}
                        />
                         <MyTextArea
                            label="メッセージ"
                            value={message}
                            multiline
                            placeholder="依頼・相談内容（用途、時期、予算等）をご記入ください。"
                            rows={4}
                            required
                            error={messageError}
                            onChange={(e, v) => { setMessage(v); }}
                            onError={() => { setMessageError(true); }}
                            onSuccess={() => { setMessageError(false); }}
                        />
                        <MySendArea>
                            <ColorButton onClick={onSend} disabled={!canSend}>送信</ColorButton>
                        </MySendArea>
                    </MyFormArea>
                </MyContents>
            </MyContentsArea>
            <MyButtonArea>
                <GoToPageButton to="/" text="TOPに戻る" variant="medium" />
            </MyButtonArea>
        </MyContactArea>
    );
}