import * as React from "react";
import { styled, MUIStyledCommonProps, Theme } from "@mui/system";
import { Button, ButtonProps } from "@mui/material";

export interface IColorButtonProps extends ButtonProps {}

export default function ColorButton({ ...props }: IColorButtonProps) {

    const baseStyle: React.CSSProperties = { 
        fontSize: "2.0rem", 
        fontWeight: 500,
        borderRadius: "3.0rem",
        padding: "0.1rem 2.0rem"
    };
    const activeStyle: React.CSSProperties = {
        ...baseStyle,
        backgroundColor: "#B3F3E0",
        color: "#000000"
    }
    const disabledStyled: React.CSSProperties = {
        ...baseStyle,
        backgroundColor: "whitesmoke",
        color: "gray"
    }

    return (
        <Button 
            {...props}
            variant="contained"
            style={props.disabled ? disabledStyled : activeStyle}
        />
    );
}