import * as React from "react";
import PageHeader from "../components/blocks/pageHeader";
import PageBody from "../components/blocks/pageBody";
import ContactDetailArea from "../components/molecules/contactDetailArea";

/** Contactページ */
export default function Contact() {
  return (
    <>
      <PageHeader />
      <PageBody>
        <ContactDetailArea />
      </PageBody>
    </>
  );
}